<template>
  <div class="navbar-container d-flex content align-items-center">

    <ul class="nav navbar-nav d-xl-none">
      <li class="nav-item">
        <b-link class="nav-link"
          @click="toggleVerticalMenuActive">
          <feather-icon
            icon="MenuIcon"
            size="21"/>
        </b-link>
      </li>
    </ul>

    <div class="bookmark-wrapper align-items-center flex-grow-1 d-none d-lg-flex">
    </div>

    
    <notifications-component></notifications-component>


    <b-navbar-nav class="nav align-items-center ml-auto">
      <b-nav-item-dropdown right
        toggle-class="d-flex align-items-center dropdown-user-link"
        class="dropdown-user">
        <template #button-content>

          <div class="d-sm-flex d-none user-nav">
            <p class="user-name font-weight-bolder mb-0">
              {{nameUser}}
            </p>
            <span class="user-status">{{emailUser}}</span>
          </div>
          <b-avatar size="40"
            badge
            variant="light-secondary"
            :text=nameAvatar
            class="badge-minimal"
            badge-variant="success"/>
        </template>

        <b-dropdown-item link-class="d-flex align-items-center" @click.prevent="_profile()">
          <feather-icon
            size="16"
            icon="UserIcon"
            class="mr-50"/>
          <span>Perfil</span>
        </b-dropdown-item>

        <b-dropdown-divider />

        <b-dropdown-item link-class="d-flex align-items-center" @click.prevent="_signOut()">
          <feather-icon
            size="16"
            icon="LogOutIcon"
            class="mr-50"/>
          <span>Salir</span>
        </b-dropdown-item>
      </b-nav-item-dropdown>
    </b-navbar-nav>
  </div>
</template>

<script>
  import {mapActions, mapState} from 'vuex'
  import {
    BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,
  } from 'bootstrap-vue'
  import DarkToggler from '@core/layouts/components/app-navbar/components/DarkToggler.vue'
  import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
  import NotificationsComponent from "./notifications/NotificationsComponent.vue"; 

  export default {
    components: {
      BLink,
      BNavbarNav,
      BNavItemDropdown,
      BDropdownItem,
      BDropdownDivider,
      BAvatar,
      DarkToggler,
      NotificationsComponent
    },
    props: {
      toggleVerticalMenuActive: {
        type: Function,
        default: () => {},
      },
    },
    computed: {
      ...mapState('profile',['profile']),
      nameUser () {
        return `${this.profile.name} ${this.profile.last_name}`
      },
      emailUser () {
        return `${this.profile.email}`
      },
      nameAvatar () {
        return this.profile.name ? this.profile.name.substr(0,1) : ''
      }
    },
    mounted() {
      this._fetchProfile()
    },
    methods: {
      ...mapActions('auth',['signOut']),
      ...mapActions('profile',['fetchProfile']),
      async _signOut () {
        await this.signOut()
          .then(res =>{ 
            this.$router.push({path: '/'}).catch(err => {console.info(err.message)})
          }).catch(err => {
            this.$router.push({path: '/'}).catch(err => {console.info(err.message)})
          }).finally(() => {
            this.$PermissionHelper.detroySession();

            this.$toast({
              component: ToastificationContent,
              props: {
                title: 'Ha cerrado sesión con éxito',
                icon: 'ThumbsUpIcon',
                variant: 'success',
              },
            })
          });
      },
      async _profile () {
        this.$router.push({path: '/profile'}).catch(err => {console.info(err.message)})
      },
      async _fetchProfile () {
        await this.fetchProfile()
        .then(res => {
          
          this.$root.$emit('fetch-profile-post-actions',res);
        }) 
      },
    }
  }
</script>
