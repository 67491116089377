export default [
  {
    route: 'home',
    title: 'Inicio',
    icon: 'HomeIcon',
  },
  {
    route: 'users',
    title: 'Usuarios',
    icon: 'UsersIcon',
  },
  {
    route: 'roles',
    title: 'Roles',
    icon: 'ListIcon',
  },
  {
    route: 'permissions',
    title: 'Permisos',
    icon: 'CheckSquareIcon',
  },
  {
    route: 'projects',
    title: 'Proyectos',
    icon: 'ArchiveIcon',
  },
  {
    route: 'properties',
    title: 'Unidades',
    icon: 'GridIcon',
  },
  {
    route: 'quotations',
    title: 'Cotizaciones',
    icon: 'FileTextIcon',
  },
  {
    route: 'bookings-requests',
    title: 'S. de Reservas',
    icon: 'BookOpenIcon',
  },
  {
    route: 'bookings-orders',
    title: 'P. de Reservas',
    icon: 'BookOpenIcon',
  },
  {
    route: 'promises-requests',
    title: 'S. de Promesas',
    icon: 'BookOpenIcon',
  },
  {
    route: 'promises-orders',
    title: 'P. de Promesas',
    icon: 'BookOpenIcon',
  },
  {
    route: 'business',
    title: 'Negocios',
    icon: 'HomeIcon',
  },
  {
    route: 'agencies',
    title: 'Inmobiliarias',
    icon: 'EyeIcon',
  },
  {
    route: 'preapprovals',
    title: 'Preaprobados',
    icon: 'FileTextIcon',
  },
]
