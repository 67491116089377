<template>
  <b-navbar-nav class="nav align-items-center ml-auto fullwi">
    <b-nav-item-dropdown right
      toggle-class="d-flex align-items-center dropdown-user-link"
      class="dropdown-user noticontainer"
      ref="noticontainer"
      @hide="handlerHide"
      >
        <template #button-content>
          <feather-icon size="16" icon="BellIcon" class="mr-50" @click.prevent="hideCountCircle()"/>
          <div v-if="show_count_message">
            <span class="badge_notification">{{new_message_count}}</span>
          </div> 
        </template>  
        <div v-for="(message,index) in messages" class='' v-bind:key="index">
          <b-dropdown-item link-class="">
            <span v-html="message"></span>
          </b-dropdown-item> 
          <b-dropdown-divider v-if="messages.length > 1"/>
        </div> 
      </b-nav-item-dropdown>
    </b-navbar-nav>
</template>

<script>
  import {mapActions, mapState} from 'vuex'
  import {BLink, BNavbarNav, BNavItemDropdown, BDropdownItem, BDropdownDivider, BAvatar,} from 'bootstrap-vue'

  export default {
    name: 'NotificationsComponent',
    components: {
      BLink,
      BNavbarNav,
      BNavItemDropdown,
      BDropdownItem,
      BDropdownDivider,
      BAvatar,
    },
    mounted(){
      this.$root.$on('fetch-profile-post-actions',
          (data) => {
            if(data.data.me.id){
              this.user_id_loaded = data.data.me.id;
              this.setPusher(); 
              this._getNotifications();
            }
          }
       ); 
    },
    data: function() {
     return  {   
        messages:['No existen notificaciones'],
        show_count_message:false,
        new_message_count:0,
        user_id_loaded :0
     }
    },
    methods: {
      ...mapActions('users',['getNotifications']),
      handlerHide(){ 
        this.hideCountCircle();
      },
      setPusher(){
        var activeChannelPreapproval = false;
        var activeChannelQuotations = false;
        var activeChannelRukan = false;
        var activeChannelPowerbi = false;

        if(this.$store.state.profile.profile.roles){
          this.$store.state.profile.profile.roles.forEach(role => {
            if(role.name){if(role.name.toLowerCase() == "preapprovaluser"){activeChannelPreapproval = true;}}
            if(role.name){if(role.name.toLowerCase() == "realstateadmin"){activeChannelQuotations = true;}}
            if(role.name){if(role.name.toLowerCase() == "realstateuser"){activeChannelQuotations = true;}}
            if(role.name){if(role.name.toLowerCase() == "realstatevendor"){activeChannelQuotations = true;}}
            if(role.name){if(role.name.toLowerCase() == "admin"){activeChannelPreapproval = true;activeChannelQuotations = true;activeChannelRukan = true;activeChannelPowerbi = true;}}
            if(role.name){if(role.name.toLowerCase() == "superadmin"){activeChannelPreapproval = true;activeChannelQuotations = true;}}
          });
        }

        var pusher = new Pusher(process.env.VUE_APP_PUSHER_KEY, {cluster: process.env.VUE_APP_CLUSTER});
        let that = this;
        var channel = pusher.subscribe('all_users');

        channel.bind('all_users', function(data) {
          that.addMessage(data);}
        );

        channel.bind('user_id_'+this.$store.state.profile.profile.id, function(data) {
          that.addMessage(data);
        });

        if(activeChannelPreapproval){
          var preapproval_channel = pusher.subscribe('preapproval');
          preapproval_channel.bind('new-preapproval', function(data) {
            var real_message = {}; 
            real_message.message = "Nuevo pre aprobado";
            var noti_title = "Solicitud de pre-aprobación Subsidios.cl";if(data.notification_title){noti_title = data.notification_title;}
            var noti_message = "Solicitud de pre-aprobación Subsidios.cl";if(data.notification_message){noti_message = data.notification_message;} 
            real_message.message = '<div class="noti_title pushernoti">'+noti_title+'</div><div class="noti_body">'+noti_message+'</div>';
            that.addMessage(real_message);
          });
        }

        if(activeChannelQuotations){
          var preapproval_channel = pusher.subscribe('quotations');
          preapproval_channel.bind('new-quotation', function(data) {
            var real_message = {}; 
            real_message.message = "Nueva cotizacion";
            var noti_title = "Nueva cotizacion";if(data.notification_title){noti_title = data.notification_title;}
            var noti_message = "Nueva cotizacion";if(data.notification_message){noti_message = data.notification_message;} 
            real_message.message = '<div class="noti_title pushernoti">'+noti_title+'</div><div class="noti_body">'+noti_message+'</div>';
            that.addMessage(real_message);
          });
          var preapproval_channel = pusher.subscribe('bookings');
          preapproval_channel.bind('new-booking', function(data) {
            var real_message = {};
            real_message.message = "Nueva Solicitud de Reserva";
            var noti_title = "Nueva Solicitud de Reserva";if(data.notification_title){noti_title = data.notification_title;}
            var noti_message = "Nueva Solicitud de Reserva";if(data.notification_message){noti_message = data.notification_message;}
            real_message.message = '<div class="noti_title pushernoti">'+noti_title+'</div><div class="noti_body">'+noti_message+'</div>';
            that.addMessage(real_message);
          });
          preapproval_channel.bind('paid-booking', function(data) {
            var real_message = {};
            real_message.message = "Pago de reserva";
            var noti_title = "Pago de reserva";if(data.notification_title){noti_title = data.notification_title;}
            var noti_message = "Pago de reserva";if(data.notification_message){noti_message = data.notification_message;}
            real_message.message = '<div class="noti_title pushernoti">'+noti_title+'</div><div class="noti_body">'+noti_message+'</div>';
            that.addMessage(real_message);
          });
          var preapproval_channel = pusher.subscribe('promises');
          preapproval_channel.bind('new-promise', function(data) {
            var real_message = {};
            real_message.message = "Nueva solicitud de promesa";
            var noti_title = "Nueva solicitud de promesa";if(data.notification_title){noti_title = data.notification_title;}
            var noti_message = "Nueva solicitud de promesa";if(data.notification_message){noti_message = data.notification_message;}
            real_message.message = '<div class="noti_title pushernoti">'+noti_title+'</div><div class="noti_body">'+noti_message+'</div>';
            that.addMessage(real_message);
          });
          preapproval_channel.bind('paid-promise', function(data) {
            var real_message = {};
            real_message.message = "Pago de promesa";
            var noti_title = "Pago de promesa";if(data.notification_title){noti_title = data.notification_title;}
            var noti_message = "Pago de promesa";if(data.notification_message){noti_message = data.notification_message;}
            real_message.message = '<div class="noti_title pushernoti">'+noti_title+'</div><div class="noti_body">'+noti_message+'</div>';
            that.addMessage(real_message);
          });
        }

        if(activeChannelRukan){
          var payment_channel = pusher.subscribe('robot-rukan');
          payment_channel.bind('fail-robot', function(data) {
            var real_message = {};
            real_message.message = "Notificacion de instancia de Robot Rukan";
            var noti_title = "Notificacion de Robot Rukan";if(data.notification_title){noti_title = data.notification_title;}
            var noti_message = "Notificacion de instancia de Robot Rukan";if(data.notification_message){noti_message = data.notification_message;}
            real_message.message = '<div class="noti_title pushernoti">'+noti_title+'</div><div class="noti_body">'+noti_message+'</div>';
            that.addMessage(real_message);
          });
        }

        if(activeChannelPowerbi){
          var payment_channel = pusher.subscribe('powerbi-icuadra');
          payment_channel.bind('success-send', function(data) {
            var real_message = {};
            real_message.message = "Notificacion de instancia de Power-Bi Icuadra";
            var noti_title = "Notificacion de Power-Bi Icuadra";if(data.notification_title){noti_title = data.notification_title;}
            var noti_message = "Notificacion de Power-Bi Icuadra";if(data.notification_message){noti_message = data.notification_message;}
            real_message.message = '<div class="noti_title pushernoti">'+noti_title+'</div><div class="noti_body">'+noti_message+'</div>';
            that.addMessage(real_message);
          });
          payment_channel.bind('error-send', function(data) {
            var real_message = {};
            real_message.message = "Notificacion de instancia de Power-Bi Icuadra";
            var noti_title = "Notificacion de Power-Bi Icuadra";if(data.notification_title){noti_title = data.notification_title;}
            var noti_message = "Notificacion de Power-Bi Icuadra";if(data.notification_message){noti_message = data.notification_message;}
            real_message.message = '<div class="noti_title pushernoti">'+noti_title+'</div><div class="noti_body">'+noti_message+'</div>';
            that.addMessage(real_message);
          });
        }

        pusher.connection.bind('state_change', function(states) {console.log(states);});
      },
      async _getNotifications (show_badge = false) {   
        console.log("calling _getNotifications");
        var that = this;
        var user_id = this.user_id_loaded;  
          await this.getNotifications({ page: 0,limit:6,user_id:user_id})
          .then(res => {     
            that.messages = []; 
            res.data.notificationsusers.data.forEach(notificationsuser => { 
                if(notificationsuser.notification){ 
                  if(notificationsuser.notification.message){ 
                    that.messages.push("<div class='noti_title' notiid='"+notificationsuser.id+"'>"+notificationsuser.notification.title+"</div><div class='noti_body'>"+notificationsuser.notification.message+"</div>");
                  }
                }
            });
          }).catch(err => {
          }).finally(() => {
          })
      }, 
      hideCountCircle:function(){
        this.show_count_message = false;
        this.new_message_count = 0;
      },
      addMessage:function(data){
        this.show_count_message = true;
        if(this.messages.length > 0){
          if(this.messages[0] == 'No existen notificaciones'){this.messages = [];}
        }
        this.new_message_count++;
        if(data.message){
          this.messages.unshift(data.message);
        }else{
          this.messages.unshift(data);
        } 
      },
    }
  }
</script>

<style type="text/css">
  .fullwi .dropdown-menu{
    width: auto !important;
  }
  .badge_notification {
    position: absolute;
    top: -10px;
    right: -10px;
    border-radius: 50%;
    background: #dd2067;
    color: white;
    padding: 5px 8px;
    font-size: 12px;
  }
  .noti_title{
    font-weight: 700;
    font-size:12px;
  }
  .noti_body{
    font-size:14px;
  }
  .noticontainer .dropdown-menu{
 max-height: 80vh;
    overflow: auto;
  }
</style>
